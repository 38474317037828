<template>
  <div v-if="itemIds?.length" data-pageelementname="element_last_viewed_item" :data-pageelementspecification="itemIds.length" @click="recentlyViewedItemsClicked">
    <AtomsTextHeading :text-class="headingClass">{{ $t('Naposledy prohlížené') }}</AtomsTextHeading>
    <div class="relative flex items-center justify-center aspect-[1/0.41] tablet:aspect-[1/0.21] desktop:aspect-[1/0.15]">
      <OrganismsListsWareList :items="recentItems" type="recent" :with-slider-navigation="true" list-name="Poslední prohlížené produkty" class="-ml-2 -mr-2" :class="props.wareListClass"></OrganismsListsWareList>
      <div v-if="isLoading" class="absolute pt-12 text-4xl">
        <AtomsGlobalLoader />
      </div>
    </div>
  </div>
</template>
<script setup>

const props = defineProps({
  headingClass: String,
  wareListClass: String
});

const appConfig = useAppConfig();
const locale = useLocale();

const isLoading = ref(true);

const itemIds = useRecentlyViewedItems().getItemIds();
let recentItems;

onMounted(async () => {
  if (itemIds.length > 0 && process.client) {

    recentItems = (await useApiFetch(`/api/wareList?withoutParameters=true&currencyId=${locale.getLocale().currencyId}&languageId=${locale.getLocale().languageId}`,
      {
        method: 'POST',
        body: {
          filter: {
            wareId: { in: itemIds }
          }
        }
      })).wareList.wares.items;

    isLoading.value = false;
  }
});

const recentlyViewedItemsClicked = () => {
    useBaseAnalytics().pushEvent('user_interaction', {
        interaction_name: 'group_recent_items_click'
    });
};

</script>